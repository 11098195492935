
import { defineComponent, onMounted, ref, reactive } from "vue";
import { getOrganization } from "@/core/services/JwtService";
import Multiselect from "@vueform/multiselect";
import ApiService from "@/core/services/ApiService";
import { getClaim } from "@/api/claim.api";
import { useRouter } from "vue-router";
import { Claim } from "@/modules/claims/claim.model";
import { useStore } from "vuex";
import { RevenueCode } from "../encounter/EncounterComponentV2.vue";
import { getDictionary, getRevenueDictionary } from "@/api/dictionaries.api";
import DecimalInput from "@/components/ABilling/DecimalInput.vue";

export interface Data {
  isDirty: boolean;
  claim?: Claim;
  dict: {
    hcpcs: [];
    revenueCodes: RevenueCode[];
    hcpcsModifiers: [];
    diagnosisCodes: [];
  };
}

export default defineComponent({
  name: "ServicesComponent",
  props: ["claim", "claimId"],
  components: { },
  setup(props, ctx) {
    const store = useStore();

    const router = useRouter();
    let organizationId = ref<string | null>("");
    let data = reactive<Data>({
      isDirty: false,
      claim: undefined,
      dict: {
        hcpcs: [],
        revenueCodes: [],
        hcpcsModifiers: [],
        diagnosisCodes: [],
      },
    });

    onMounted(async () => {
      organizationId.value = getOrganization();
      await getDict();
      let claim = props.claim;
      if (!claim) {
        claim = await getClaim(props.claimId);
      }
      data.claim = claim;
    });

    async function getDict() {
      data.dict.hcpcs = await getDictionary(0, "");
      data.dict.hcpcsModifiers = await getDictionary(2, "");
      data.dict.diagnosisCodes = await getDictionary(3, "");
      data.dict.revenueCodes = await getRevenueDictionary("");
    }

    return {
      organizationId,
      data,
    };
  },
});
